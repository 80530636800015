import uniqid from 'uniqid'
import { skills, frontendSkills, backendSkills, othersSkills } from '../../portfolio'
import './Skills.css'

const Skills = () => {
  if (!skills.length) return null
  if (!frontendSkills.length) return null
  if (!backendSkills.length) return null
  if (!othersSkills.length) return null

  return (
    <section className='section skills' id='skills'>
      <h2 className='section__title'>Skills</h2>
      <div className="skills-container">
        <div className="skills-column">
          <h5 className='section__title'>Frontend</h5>
          <ul className='skills__list'>
            {frontendSkills.map((skill) => (
              <li key={uniqid()} className='skills__list-item btn btn--plain'>
                {skill}
              </li>
            ))}
          </ul>
        </div>
        <br />
        <div className="skills-column">
          <h5 className='section__title'>Backend</h5>
          <ul className='skills__list'>
            {backendSkills.map((skill) => (
              <li key={uniqid()} className='skills__list-item btn btn--plain'>
                {skill}
              </li>
            ))}
          </ul>
        </div>
        <br />
        <div className="skills-column">
          <h5 className='section__title'>Others</h5>
          <ul className='skills__list'>
            {othersSkills.map((skill) => (
              <li key={uniqid()} className='skills__list-item btn btn--plain'>
                {skill}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Skills
