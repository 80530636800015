import { useContext } from 'react'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeContext } from './contexts/theme'
import Portfolio from './components/Portfolio/Portfolio'
import Blog from './components/Blog/Blog'
import './App.css'

const App = () => {
  const [{ themeName }] = useContext(ThemeContext)

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Portfolio />,
    },
    // {
    //   path: "/blog",
    //   element: <Blog />,
    // },
  ]);

  return (
    <div id='top' className={`${themeName} app`}>
      <div id="content"
        // className="content"
      >
        <RouterProvider router={router} />
      </div>
    </div>
  )
}

export default App
