import Header from '../Header/Header'
import ScrollToTop from '../ScrollToTop/ScrollToTop'
import Footer from '../Footer/Footer'
import About from '../About/About'
import Projects from '../Projects/Projects'
import Skills from '../Skills/Skills'
import Contact from '../Contact/Contact'

const Portfolio = () => (
    <div className='view'>
        <Header />

        <main>
            <About />
            <Projects />
            <Skills />
            <Contact />
        </main>

        <ScrollToTop />
        <Footer />
    </div>
);

export default Portfolio
