import { useLocation, Link } from 'react-router-dom';
import { header } from '../../portfolio'
import Navbar from '../Navbar/Navbar'
import './Header.css'

const Header = () => {
  const location = useLocation();

  const { title, blogTitle } = header

  return (
    <header className='header center'>
      <h3>
        {location.pathname === '/' && <Link className='link' to='/'>{title}</Link>}
        {/* {location.pathname === '/' && <Link className='link' to='/blog'>{title}</Link>} */}
        {/* {location.pathname === '/blog' && <Link className='link' to='/'>{blogTitle}</Link>} */}
        {/* {homepage ? (
          <a href={homepage} className='link'>
            {location.pathname === '/' && title}
            {location.pathname === '/blog' && blogTitle}
          </a>
        ) : (
          title
        )} */}
      </h3>
      <Navbar />
    </header>
  )
}

export default Header
