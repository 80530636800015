const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: '/',
  title: 'Portfolio.',
  blogTitle: 'Blog.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Shehjad',
  role: 'FullStack Software Engineer',
  description:
    'Motivated and result-oriented software engineer with 3+ years of experience and expertise in building modern web applications. Passionate about learning new technologies and staying at the forefront of industry trends.',
  resume: 'https://example.com/',
  social: {
    linkedin: 'https://www.linkedin.com/in/shehjaddev/',
    github: 'https://github.com/shehjaddev/',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'TextQ',
    description:
      'A collection of text manipulation tools that can be used to manipulate text in various ways.',
    stack: ['Typescript', 'NextJS'],
    sourceCode: 'https://github.com/shehjaddev/textq',
    livePreview: 'https://textq.vercel.app/',
  },
  {
    name: 'Quiz Realm',
    description:
      'A simple quiz web app created using Django with PostgreSQL database to learn Django.',
    stack: ['Python', 'Django', 'PostgreSQL'],
    sourceCode: 'https://github.com/shehjad0/Quiz-Realm',
    livePreview: 'https://quiz-zone.onrender.com/',
  },
  {
    name: 'Ecommerce Backend',
    description:
      'A ecommerce rest api created using typescript, nodejs, expressjs, mongodb etc.',
    stack: ['ExpressJS', 'Mongoose', 'Typescript'],
    sourceCode:
      'https://github.com/shehjad97/Ecommerce-Backend-Node-Typescript',
  },
  // {
  //   name: 'Tour App',
  //   description:
  //     'A simple responsive tour booking website with interactive comments and tour booking functionality.',
  //   stack: ['MERN', 'NextJS'],
  //   sourceCode: 'https://github.com/shehjad97/Tour_App/',
  //   livePreview: 'https://its-a-tour-app.vercel.app/',
  // },
  // {
  //   name: 'Project 2',
  //   description:
  //     'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
  //   stack: ['SASS', 'TypeScript', 'React'],
  //   sourceCode: 'https://github.com',
  //   livePreview: 'https://github.com',
  // },
  // {
  //   name: 'Project 3',
  //   description:
  //     'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
  //   stack: ['SASS', 'TypeScript', 'React'],
  //   sourceCode: 'https://github.com',
  //   livePreview: 'https://github.com',
  // },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'React',
  'Redux',
  'SASS',
  'Material UI',
  'Git',
  'CI/CD',
  'Jest',
  'Enzyme',
]

const frontendSkills = [
  'NextJS',
  'ReactJS',
  'Redux Toolkit',
  'RTK Query',
  'Javascript',
  'Typescript',
  'Tailwind CSS',
  'HTML',
  'CSS',
  'Sass',
]

const backendSkills = [
  'ExpressJS',
  // 'MongoDB (Mongoose)',
  // 'PostgreSQL (Sequelize)',
  'MongoDB',
  'PostgreSQL',
  'Socket.io',
  'Redis',
  'GraphQL',
  'BullMQ',
  'Jest',
  'Swagger UI',
]

const othersSkills = [
  'Linux',
  'Git',
  'Github',
  'Bash',
  'Nginx',
  'C++',
  'Python',
  'Docker',
  'PM2',
  'AWS (EC2, S3)',
  'Postman',
  'Firebase',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'shehjadcse@gmail.com',
}

export {
  header,
  about,
  projects,
  skills,
  frontendSkills,
  backendSkills,
  othersSkills,
  contact,
}
